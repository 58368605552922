<template>
  <div class="full">
    <van-nav-bar title="选择支付方式" @click-left="back()">
      <template #left>
        <van-icon color="#3E3E3E" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="cut_down">支付剩余时间：
        <van-count-down class="d_i" :time="cutDownTime" v-if="isCutDown" />
        <span class="time" v-else>订单已失效</span>
      </div>
      <div class="white">
      <div class="price" @click="openDetail()">
        <h2>应付金额：</h2>
        <em>￥{{order.yj?order.yj/100:0}}</em>
        <span class="real">￥{{order.totalSellPrice?order.totalSellPrice/100:0}}</span>
        <van-tag v-if="order.discountAmount" round plain color="#FF3E6E" text-color="#FF3E6E">消费劵 抵扣{{order.discountAmount?order.discountAmount/100:0}}</van-tag>
        <span class="codoew">订单详情
          <van-icon name="arrow-down" />
        </span>
      </div>
      <div class="detail" v-if="showDetail">
        <div class="box" >
          <div class="goods_info">
            <span class="name">{{orderDetails.orderDetail.commoditytName}}</span>
            <span class="num">x{{orderDetails.orderDetail.quantity}}</span>
            <div class="price">￥{{orderDetails.commodity.sellPrice?orderDetails.commodity.sellPrice/100:0}}</div>
          </div>
          <div class="tag" v-if="order.discountAmount">
            <van-tag round plain color="#FF3E6E" text-color="#FF3E6E">消费劵 抵扣{{order.discountAmount?order.discountAmount/100:0}}</van-tag>
          </div>
        </div>
        <div class="buy">
          <span>实付金额</span>
          <span>￥{{order.totalSellPrice?order.totalSellPrice/100:0}}</span>
        </div>
      </div>
    </div>
      <div class="pay_way" v-if="isCutDown">
        <div class="item d_f ali_c">
          <div class="p_icon">
            <img src="../imgs/p_wechat.png" alt="">
          </div>
          <div class="flex_1 ali_c d_f j_s bb_none">
            <span>微信支付</span>
            <!--<img src="../img/p_uncheck.png" alt="">-->
            <img src="../imgs/p_check.png" alt="">
          </div>
        </div>
      </div>
      <div class="btn t_a cur_p" @click="paySure" v-if="isCutDown">立即支付</div>
      <!-- <div class="btn t_a cur_p" @click="paySure" v-if="cutDownTime>0">确认支付</div> -->
    </div>
  </div>
</template>
<script src='./goodsPay.js'></script>
<style lang="scss" scoped src='./goodsPay.scss'></style>
