import { setTimeout } from 'core-js';
import { Image, Button, Tag, NavBar, Icon, Popup, CountDown, Toast } from 'vant';
import md5 from 'js-md5';
import apis from '../utils/apis'
let loading = null;
export default {
  name: 'goodsPay',
  components: {
    'van-image': Image,
    'van-button': Button,
    'van-tag': Tag,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-popup': Popup,
    'van-count-down': CountDown,
  },
  data () {
    return {
      cutDownTime: 0,
      order: {}, //订单信息
      orderDetails: [],// 商品列表
      showDetail: false, //展示详情
      interVal: null,
      isCutDown:true,
      isJump:false,//z支付成功跳转
    }
  },
  methods: {
    appPayDone(code){
      let _t = this;
      if(code == 0){
        _t.interVal = setInterval(function () {
          if(_t.isJump){
            Toast.clear();
            loading.clear();
            window.clearInterval(_t.interVal);
            clearInterval(_t.interVal);
            _t.interVal = null;
            _t.getStatus()
          }else{
            _t.getStatus()
          }
        },1000);
      }else if(code == -1){
        Toast.clear();
        loading.clear();
        Toast('支付失败');
        window.clearInterval(_t.interVal);
        _t.interVal = null;
      }else if(code == -2){
        Toast.clear();
        loading.clear();
        Toast('用户取消');
        window.clearInterval(_t.interVal);
        _t.interVal = null;
      }
    },
    appPay(info){
      let _t = this;
      if (_t.$global.modelState) {
        window.android.payByWeChart(info);
      } else {
        _t.$iosMethod(function(bridge) {
          bridge.callHandler('payByWeChart', info, function(data) {

          })
        })
      }
    },
    //订单详情done
    load () {
      this.$get3({
        url: apis.findOrder + this.$route.query.orderId,
      }).then(res => {
        this.order = res.data.order
        let discountAmount = 0
        if (this.order.discountAmount) {
          discountAmount = this.order.discountAmount
        }
        // 原价
        this.order.yj = this.order.totalSellPrice + discountAmount
        if (res.data.orderDetails.length > 0) {
          this.orderDetails = res.data.orderDetails[0]
        }
        let now = new Date().getTime();
        let order = new Date(this.$renderTime(this.order.creatorTime)).getTime();
        if ((order + 15 * 60 * 1000) > now) {
          this.cutDownTime = (order + 15 * 60 * 1000) - now;
        } else {
          this.isCutDown = false;
        }
      });
    },
    //确认付款
    paySure () {
      let me = this;
      loading = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '支付中'
      });
      me.$post2({
        url: apis.pay,
        params: {
          orderNumber: me.order.orderNumber, // 	*订单号
          payType: 'app',  // 	*支付方式
          totalFee: me.order.totalSellPrice, // *总金额
          token: me.$global.token
        }
      }).then(res => {
        Toast.clear();
        loading.clear();
        let info = me.jsonInfo(res.data.formData);
        let timestamp = me.$global.modelState ? sessionStorage.getItem('timestamp') : Number(sessionStorage.getItem('timestamp'))/1000;
        let signInfo = {
          appid:info.appid,
          timestamp:timestamp,
          partnerid:info.mch_id,
          noncestr:info.nonce_str,
          package: "Sign=WXPay",
          prepayid:info.prepay_id,
        };
        console.log(signInfo)
        let stringA=me.sortAsc(signInfo);
        let stringSignTemp=stringA+"&key="+this.$global.appPayKey;
        let sign=md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
        console.log(sign)
        let paySend = {
          appid:info.appid,
          timestamp:timestamp,
          partnerid:info.mch_id,
          noncestr:info.nonce_str,
          packageName: "Sign=WXPay",
          prepayid:info.prepay_id,
          paySign:sign,
          signType:'',
          iOSLink:'',
        };
        me.appPay(JSON.stringify(paySend));
      }).catch(err => {
        Toast(err.message);
        clearInterval(me.interVal);
        me.interVal = null;
      });
    },
    sortAsc(o){
      var n = [];
      for(var k in o) n.push(k);
      n.sort();
      for(var i=0,str='';i<n.length;i++){
        var v = o[n[i]];
        if(v){
          if({}.toString.call(v)=='[object Object]')
            v = sortAsc(v);
          else if({}.toString.call(v)=='[object Array]')
            v = JSON.stringify(v).replace(/:/g,'=');
        }
        str+='&'+n[i]+'='+v;
      }
      return str.slice(1);
    },
    getStatus () {
      let me = this;
      me.$get({
        url: apis.queryOrderStatus,
        closeToast: true,
        params: {
          orderNumber: me.order.orderNumber,
          sortName: 'order_number',
          token: me.$global.token
        }
      }).then(res => {
        console.log('res.orderStatus')
        console.log(res.orderStatus)
        if (res.orderStatus == 4 || res.orderStatus == 5 || res.orderStatus == 7) {
          Toast.clear();
          window.clearInterval(me.interVal);
          me.interVal = null;
          me.isJump = true;
          setTimeout(() => {
            this.$router.push({
              path: '/paySuccessRetail',
              query: {
                orderId: me.order.orderId,
                phone: res.reservationPersonTelephone,
                commodityId: me.orderDetails.commodity.commodityId,
              }
            })
          }, 1000)

        }
      });
    },
    jsonInfo (c) {
      var jsonData = {}
      c = c.replace("{", "").replace("}", "");
      c = c.split(',')
      for (var i = 0; i < c.length; i++) {
        if (c[i].split('=').length === 2) {
          jsonData[(c[i].split('=')[0]).replace(/\s+/g, "")] = (c[i].split('=')[1]).replace(/\s+/g, "")
        } else {
          let str = ''
          for (var j = 1; j < c[i].split('=').length; j++) {
            str += (c[i].split('=')[j]).replace(/\s+/g, "") + '=';
          }
          jsonData[(c[i].split('=')[0]).replace(/\s+/g, "")] = (str.substring(0, str.length - 1))
        }
      }
      return jsonData
    },
    openDetail () {
      this.showDetail = !this.showDetail
    },
    back () {
      this.$router.go(-1)
    },
    dateFtt (fmt, date) { //author: meizz
      var o = {
        "M+": date.getMonth() + 1,     //月份
        "d+": date.getDate(),     //日
        "h+": date.getHours(),     //小时
        "m+": date.getMinutes(),     //分
        "s+": date.getSeconds(),     //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()    //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    }
  },
  created () {
    let _t = this;
    window.appPayDone = (code)=> _t.appPayDone(code);
    _t.$iosBridge.registerhandler('appPayDone',function (code) {
      _t.appPayDone(code)
    })
    this.load()
  },
  mounted () {
  },
  beforeUnmount () {
    window.clearInterval(this.interVal);
    this.interVal = null
  },
  onBeforeRouteLeave (to, from, next) {
    window.clearInterval(this.interVal);
    sessionStorage.clear();
    console.log('interVal')
    console.log(this.interVal)
    this.interVal = null
    sessionStorage.setItem('LOGIN_STATUS', '2');
    sessionStorage.setItem('token', this.$global.token);
    next();
  },
}
